<template>
  <div
    class="footer-container"
    :class="{ security: security, isKeyPal: isKeyPal }"
  >
    <div class="content">
      <div class="follow-wrap left">
        <img class="logo" :src="logoImg" @click="goHome" />
        <div>
          <div class="footer-follows">
            <a
              class="follow-item"
              :class="[item.class]"
              v-for="(item, index) in followList"
              :key="index"
              @click="openFollow(item)"
            >
              <div class="footer-follow"></div>
            </a>
          </div>
          <div class="copyright" :class="{ security: security }">
            ©2025 Transit Finance
          </div>
        </div>
      </div>
      <div class="footer-wrap">
        <div
          v-for="(item, index) in footerList"
          :key="index"
          class="footer-list"
        >
          <div class="title" :class="{ security: security }">
            {{ item.title }}
          </div>

          <div class="footer-link-wrap">
            <div
              class="footer-link"
              v-for="(data, dataIndex) in item.data"
              :key="dataIndex"
            >
              <a
                class="pointer"
                :class="{ security: security }"
                :href="data.url ? data.url : 'javascript:void(0)'"
                :target="data.local ? '_self' : '_blank'"
                @click.stop="onFooterLink(data)"
                >{{ data.title }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import darkMode from '../../mixins/darkMode';
import link from '../../mixins/link';
import { EXPLORER_TRANSIT_DOMAIN } from '../../utils/config';
export default {
  props: {
    security: {
      type: Boolean,
    },
    isKeyPal: {
      type: Boolean,
    },
  },
  mixins: [darkMode, link],
  data() {
    return {
      explorer: EXPLORER_TRANSIT_DOMAIN,
    };
  },
  computed: {
    logoImg() {
      return require(`../../assets/home/Transit${this.darkModeImg}.png`);
    },
    followList() {
      return [
        {
          url: 'https://twitter.com/TransitFinance',
          class: `follow-item-1${this.darkModeImg}`,
        },
        {
          url: 'https://t.me/Transit_Finance',
          isTelegram: true,
          class: `follow-item-2${this.darkModeImg}`,
        },
        {
          url: 'mailto:service@transit.finance',
          class: `follow-item-3${this.darkModeImg}`,
        },
        {
          url: 'https://github.com/transit-finance',
          class: `follow-item-4${this.darkModeImg}`,
        },
        {
          url: 'https://fans.tokenpocket.pro/c/transit-swap-announcement-transit-swap/14',
          class: `follow-item-5${this.darkModeImg}`,
        },
      ];
    },
    footerList() {
      return [
        {
          title: this.$t('footer.Features.title'),
          data: [
            {
              title: this.$t('footer.Features.swap'),
              url: this.swapUrl,
            },
            // {
            //   title: this.$t('footer.Features.bridge'),
            //   url: `https://swap.transit.finance/?locale=${this.$i18n.locale}#/`,
            // },
            {
              title: this.$t('footer.Features.market'),
              url: this.swapMarketUrl,
              // comingSoon: true,
            },
            {
              title: this.$t('footer.Features.buyCrypto'),
              url: this.transitBuyUrl,
            },
            {
              title: this.$t('footer.Features.explorer'),
              // url: this.explorer.replace('hash/', ''),
              url: this.transitExplorerUrl,
            },
            {
              title: this.$t('header.inscribeTool'),
              url: `https://tp-lab.tokenpocket.pro/insc/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}#/`,
            },
          ],
        },
        {
          title: this.$t('footer.NFT.title'),
          data: [
            {
              title: this.$t('footer.NFT.design'),
              url: 'https://tp-lab.tokenpocket.pro/mint-skin-2/index.html#/',
            },
            {
              title: this.$t('footer.NFT.transitNFT'),
              url: 'https://nft.transit.finance/#/',
            },
            {
              title: this.$t('footer.NFT.marketplace'),
              url: 'https://nft.transit.finance/waitlist/#/',
            },
          ],
        },
        {
          title: this.$t('footer.Governance.title'),
          data: [
            {
              title: this.$t('footer.Governance.dao'),
              local: true,
              routePath: '/stake',
            },
            {
              title: this.$t('footer.Governance.donation'),
              local: true,
              routePath: '/donation',
            },
            {
              title: this.$t('footer.Governance.fee'),
              local: true,
              routePath: '/fee',
            },
          ],
        },
        {
          title: this.$t('footer.Community.title'),
          data: [
            {
              title: this.$t('footer.Community.developers'),
              url:
                this.$i18n.locale === 'zh'
                  ? 'https://docs.transit.finance/cn/'
                  : 'https://docs.transit.finance/en/',
            },
            {
              title: this.$t('footer.Community.forum'),
              url: 'https://fans.tokenpocket.pro/c/transit-swap-announcement-transit-swap/14',
            },
            {
              title: this.$t('footer.Community.twitter'),
              url: 'https://twitter.com/TransitFinance',
            },
            // {
            //   title: this.$t('footer.Community.downloadTP'),
            //   url:
            //     this.$i18n.locale === 'zh'
            //       ? 'https://tp.transit.finance/zh/download/app'
            //       : 'https://tp.transit.finance/en/download/app',
            // },
          ],
        },
        {
          title: this.$t('footer.Help.title'),
          data: [
            {
              title: this.$t('footer.Help.contactUs'),
              contactUs: true,
              local: true,
            },
            {
              title: this.$t('footer.Help.helpCenter'),
              url:
                this.$i18n.locale === 'zh'
                  ? 'https://docs.transit.finance/cn/'
                  : 'https://docs.transit.finance/en/',
            },
          ],
        },
        {
          title: this.$t('footer.Links.title'),
          data: [
            {
              title: this.$t('footer.Links.TP'),
              url: 'https://www.tpwallet.io/'
            },
            {
              title: this.$t('footer.Links.Trading_View'),
              url: 'https://tradingview.com/',
            },
            {
              title: this.$t('footer.Links.Terms'),
              local: true,
              routePath: '/terms',
            },
            {
              title: this.$t('footer.Links.Privacy_Policy'),
              local: true,
              routePath: '/privacy-policy',
            },
            {
              title: this.$t('footer.Links.bugBounty'),
              url: `https://bugrap.io/bounties/Transit%20swap`
            },
          ],
        },
      ];
    },
  },
  methods: {
    goHome() {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' });
      }
    },
    openFollow(item) {
      if (item.isTelegram) {
        this.$mitt.emit('tg-modal', { show: true, url: item.url })
      } else {
        window.open(item.url);
      }
    },
    onFooterLink(data) {
      if (data.lang) {
        this.$i18n.locale = data.lang;
        document.title = this.$t('common.documentTitle');
      } else if (data.routePath) {
        this.$router.push({ path: data.routePath });
      } else if (data.contactUs) {
        this.$mitt.emit('contact-us', { show: true });
      } else if (data.comingSoon) {
        this.$toast({
          text: this.$t('comingSoon'),
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer-container {
  background: var(--bg);
  padding: 68px 0 78px;

  .loop1(@i) when (@i > 0) {
    .follow-item-@{i} {
      border: 1px solid var(--border-1);
      .footer-follow {
        background: url('../../assets/footer/@{i}.png') no-repeat 100% / contain;
      }
      cursor: pointer;
      &:hover {
        background: #2980fe;
        border: 1px solid #2980fe;
        .footer-follow {
          background: url('../../assets/footer/@{i}-on.png') no-repeat 100% /
            contain;
        }
      }
    }
    .follow-item-@{i}-dark {
      border: 1px solid var(--border-1);
      .footer-follow {
        background: url('../../assets/footer/@{i}-dark.png') no-repeat 100% /
          contain;
      }
      cursor: pointer;
      &:hover {
        background: #2980fe;
        border: 1px solid #2980fe;
        .footer-follow {
          background: url('../../assets/footer/@{i}-on.png') no-repeat 100% /
            contain;
        }
      }
    }
    .loop1((@i - 1));
  }

  .loop1(5);

  .content {
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left {
      font-size: 0;
      display: flex;
      flex-direction: column;
    }
    .follow-wrap {
      display: flex;
      .logo {
        width: 128px;
        cursor: pointer;
      }
      .footer-follows {
        display: flex;
        margin: 32px 0 24px;
        .follow-item {
          width: 44px;
          height: 44px;
          // border: 1px solid var(--border-1);
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-child(n + 2) {
            margin-left: 12px;
          }
          .footer-follow {
            width: 24px;
            height: 24px;
          }
        }
      }
      .copyright {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        color: var(--color-1);
      }
    }
    .footer-wrap {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: 313px;
      .footer-list {
        padding: 0 10px;
        .title {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: var(--color-5);
          line-height: 22px;
        }
        .footer-link-wrap {
          margin-top: 20px;
          :nth-child(n + 2) {
            margin-top: 16px;
          }
          .footer-link {
            a {
              display: flex;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Medium;
              // font-weight: 500;
              font-weight: 400;
              color: var(--color-1);
              line-height: 20px;
            }
            a:hover {
              color: var(--color-5);
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width: 1400px) {
  .footer-container {
    .content {
      padding: 0 30px;
    }
  }
}
@media screen and(max-width: 1200px) {
  .footer-container {
    .content {
      flex-direction: column;
      .footer-wrap {
        margin-top: 50px;
        margin-left: 0;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .footer-container {
    .loop1(@i) when (@i > 0) {
      .follow-item-@{i} {
        border: 1px solid var(--border-1);
        .footer-follow {
          background: url('../../assets/footer/@{i}.png') no-repeat 100% /
            contain;
        }
        cursor: pointer;
        &:hover {
          background: none;
          border: 1px solid var(--border-1);
          .footer-follow {
            background: url('../../assets/footer/@{i}.png') no-repeat 100% /
              contain;
          }
        }
      }
      .follow-item-@{i}-dark {
        .footer-follow {
          background: url('../../assets/footer/@{i}-dark.png') no-repeat 100% /
            contain;
        }
        cursor: pointer;
        &:hover {
          background: none;
          // border: 1px solid #2980fe;
          .footer-follow {
            background: url('../../assets/footer/@{i}.png') no-repeat 100% /
              contain;
          }
        }
      }
      .loop1((@i - 1));
    }

    .loop1(5);

    .content {
      .follow-wrap {
        // align-items: center;
        .logo {
          width: 115px;
        }
        .footer-follows {
          margin: 22px 0 16px;
          // justify-content: center;
          .footer-follow {
            width: 24px;
            height: 24px;
            &:nth-child(n + 2) {
              margin-left: 20px;
            }
          }
        }
        .copyright {
          font-size: 12px;
        }
      }
      .footer-wrap {
        margin-top: 15px;
        .footer-list {
          width: 45%;
          padding: 0;
          // &:nth-child(2n) {
          //   .title {
          //     text-align: right;
          //   }
          //   .footer-link-wrap {
          //     .footer-link {
          //       a {
          //         justify-content: flex-end;
          //       }
          //     }
          //   }
          // }
          &:nth-child(n + 3) {
            margin-top: 30px;
          }
          .title {
            padding: 0;
            text-align: left;
            font-size: 14px;
          }
          .footer-link-wrap {
            margin-top: 14px;
            .footer-link {
              &:nth-child(n + 2) {
                margin-top: 15px;
              }
              a {
                font-size: 13px;
              }
              .security:hover {
                color: rgba(255, 255, 255, 0.4);
              }
              a:hover {
                color: rgba(16, 16, 16, 0.4);
                color: var(--color-1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
